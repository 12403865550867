import { fetcher } from "./queryFn";

export async function loginFn({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  return fetcher("auth/login", {
    data: {
      email,
      password,
    },
  });
}

export async function signupFn({
  email,
  password,
  username,
  confirmpassword,
}: {
  email: string;
  password: string;
  username: string;
  confirmpassword: string;
}) {
  return fetcher("auth/signup", {
    data: {
      email,
      password,
      username,
      confirmpassword,
    },
  });
}
