import { useEffect } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../data/services/user_manager";
import Navbar from "../../components/home/Navbar";
import image from "../../assets/images/books.jpg";
export default function AuthLayout() {
  const navigate = useNavigate();
  const auth = useAuth();
  const [params] = useSearchParams();

  useEffect(() => {
    if (auth.user) {
      navigate(
        params.get("redirect_url") ??
          (auth.user.role === "admin" ? "/admin" : "/user"),
        {
          replace: true,
        }
      );
    }
  }, [navigate, auth.user, params]);
  return (
    <>
      <Navbar />
      <div className="bg-gray-950 min-h-screen px-1 py-10 flex items-center flex-col h-screen overflow-auto">
        <img src={image} className="absolute opacity-30 h-full w-full top-0" />
        <div className="p-4 rounded-lg shadow-md bg-white mt-8 relative z-10">
          <Outlet />
        </div>
      </div>
    </>
  );
}
