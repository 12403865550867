import { useState } from "react";
import { ZodError, ZodIssue } from "zod";
import { AppLogo } from "../../components/AppLogo";
import { ButtonBase } from "../../components/base/ButtonBase";
import InputBase from "../../components/base/InputBase";
import { signupFn } from "../../data/actions/authFn";
import { ValidationError } from "../../data/actions/queryFn";
import { useAuth } from "../../data/services/user_manager";
import { Link } from "react-router-dom";
import { signUpSchema } from "../../data/schemas/signupSchema";

export default function SignupPage() {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [error, setError] = useState<any>(null);
  const auth = useAuth();

  return (
    <>
      <div className="container max-w-md mx-auto py-16 pt-8 flex-col flex items-center">
        <div className="pointer-events-none mb-8">
          <AppLogo />
        </div>

        <form
          method="POST"
          onSubmit={async (e) => {
            setError(null);
            e.preventDefault();
            try {
              const data = signUpSchema.parse({
                email,
                username,
                password,
                confirmpassword: confirmPassword,
              });
              const { data: user } = await signupFn(data);
              auth.setUser(user);
            } catch (e) {
              if (e instanceof ZodError) setError({ errors: e.errors });
              else if (e instanceof ValidationError) {
                setError(e.cause!);
              } else {
                setError(e as Error);
              }
            }
          }}
        >
          <p className="w-full text-sm text-center text-text">
            Create an account now to keep track of all your purchases.
          </p>
          <p className="text-sm text-red-700 mt-4 mb-2">
            {error && "error" in error ? error.error : error?.message}
          </p>
          <InputBase
            name="email"
            type="email"
            label="Email"
            error={
              error?.errors?.find(
                (e: ZodIssue) => e.path?.join(".") === "email"
              )?.message
            }
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <InputBase
            name="username"
            type="string"
            label="Choose your username"
            className="mt-4"
            error={
              error?.errors?.find(
                (e: ZodIssue) => e.path?.join(".") === "username"
              )?.message
            }
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
          <InputBase
            name="password"
            type="password"
            autoComplete="new-password"
            label="Password"
            className="mt-4"
            error={
              error?.errors?.find(
                (e: ZodIssue) => e.path?.join(".") === "password"
              )?.message
            }
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <InputBase
            name="confirmpassword"
            type="password"
            autoComplete="new-password"
            label="Confirm Password"
            className="mt-4"
            error={
              error?.errors?.find(
                (e: ZodIssue) => e.path?.join(".") === "confirmpassword"
              )?.message
            }
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />
          <div className="flex justify-between items-center mt-4 flex-wrap gap-x-4">
            <div className="text-end flex-grow mt-2">
              <span className="text-sm">Already have an account? </span>
              <Link to="/auth/login" className="text-primary text-sm mt-2">
                Log in here
              </Link>
            </div>
          </div>
          <ButtonBase className="mx-auto block mt-8" type="submit">
            Create your account
          </ButtonBase>
        </form>
      </div>
    </>
  );
}
