import { useState } from "react";
import { ZodError, ZodIssue } from "zod";
import { AppLogo } from "../../components/AppLogo";
import { ButtonBase } from "../../components/base/ButtonBase";
import InputBase from "../../components/base/InputBase";
import { loginFn } from "../../data/actions/authFn";
import { ValidationError } from "../../data/actions/queryFn";
import { loginSchema } from "../../data/schemas/loginSchema";
import { useAuth } from "../../data/services/user_manager";
import { Link } from "react-router-dom";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [error, setError] = useState<any>(null);
  const auth = useAuth();

  return (
    <>
      <div className="container max-w-md mx-auto py-16 pt-8 flex-col flex items-center">
        <div className="pointer-events-none mb-8">
          <AppLogo />
        </div>
        <form
          method="POST"
          onSubmit={async (e) => {
            setError(null);
            e.preventDefault();
            try {
              const data = loginSchema.parse({ email, password });
              const { data: user } = await loginFn(data);
              auth.setUser(user);
            } catch (e) {
              if (e instanceof ZodError) setError({ errors: e.errors });
              else if (e instanceof ValidationError) {
                setError(e.cause!);
              } else {
                setError(e as Error);
              }
            }
          }}
        >
          <p className="text-sm text-red-700 mt-4 mb-2">
            {error && "error" in error ? error.error : error?.message}
          </p>
          <InputBase
            name="email"
            type="email"
            label="Enter your email"
            error={
              error?.errors?.find(
                (e: ZodIssue) => e.path?.join(".") === "email"
              )?.message
            }
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <InputBase
            name="password"
            type="password"
            label="Password"
            className="mt-8"
            error={
              error?.errors?.find(
                (e: ZodIssue) => e.path?.join(".") === "password"
              )?.message
            }
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <div className="flex justify-between items-center mt-4 flex-wrap">
            <Link
              to="/auth/forgot-password"
              className="text-primary text-sm block mt-2"
            >
              Forgot Password?
            </Link>
            <div className="text-end flex-grow mt-2">
              <span className="text-sm">Don't have an account? </span>
              <Link to="/auth/signup" className="text-primary text-sm mt-2">
                Create an account here
              </Link>
            </div>
          </div>
          <ButtonBase className="mx-auto block mt-8" type="submit">
            Submit
          </ButtonBase>
        </form>
      </div>
    </>
  );
}
