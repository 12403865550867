import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataView } from "../../components/admin/DataView";
import { mapResponseToPurchases } from "../../data/actions/mappers";
import { authQueryFn, fetcher, queryFn } from "../../data/actions/queryFn";
import { Purchase } from "../../data/models/purchase";
import { useAuth } from "../../data/services/user_manager";
import Header from "../../components/admin/Header";
import Navbar from "../../components/home/Navbar";
import Footer from "../../components/home/Footer";

export default function UsersPage(): JSX.Element {
  const navigate = useNavigate();
  const auth = useAuth();
  useEffect(() => {
    if (auth.user === undefined) return;
    if (!auth.user) {
      navigate("/auth/login");
    }
  }, [navigate, auth.user]);

  const {
    data: siteReviews,
    // error,
    isLoading,
  } = useQuery({
    queryKey: ["purchases"],
    select: mapResponseToPurchases,
    queryFn: authQueryFn,
  });
  return (
    <>
      <Navbar />
      <div className="container min-h-screen flex flex-col py-8 pt-24">
        <h1 className="text-xl mb-4">
          Welcome, {auth.user?.username.replace(/^./, (e) => e.toUpperCase())}
        </h1>
        <h2 className="text-lg font-semibold text-primary">My Books</h2>
        <div className="text-center py-8 text-gray-600 flex-grow">
          No books yet.
        </div>
        <div className="text-center">
          <button
            className="text-red-700"
            onClick={() => {
              fetcher("auth/logout", { method: "POST" }).then(() =>
                auth.setUser(null)
              );
            }}
          >
            Log Out
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}
